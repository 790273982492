// const api = "https://endpoint.xrscollector.com";
// const api = "http://api.xrs.ie/xrspaymentportal";
// const api = "https://api.xrscollector.com/cssp";
const api = "https://api.xrscollector.com/wexfordcocoupdates";

export const AuthenticationAPIUrl = api + "/oauth/token";
export const GetOfferAPIUrl = api + "/api/WebOffers/ReturnWebOffer";
export const GetSiteDetatilsAPIUrl = api + "/api/getSiteDetails";
export const GetcreateUserAPIUrl = api + "/api/auth/create";
export const GetcreateUserViaEmailAPIUrl = api + "/api/auth/createViaEmail";

export const GetCreateBusinesUserAPIUrl = api + "/api/auth/createbusiness";
export const GetCreatePersonalUserAPIUrl = api + "/api/auth/createpersonal";

export const GetRecreateUserAPIUrl = api + "/api/auth/recreate";
export const GetRecreateUserViaEmailAPIUrl = api + "/api/auth/recreateViaEmail";

export const GetReCreateBusinesUserAPIUrl = api + "/api/auth/recreatebusiness";
export const GetReCreatePersonalUserAPIUrl = api + "/api/auth/recreatepersonal";

export const GetPINDigitsAPIUrl = api + "/api/auth/PINDigit";
export const GetTokenURLAPI = api + "/oauth/token";
export const GetVerifyAPIUrl = api + "/api/auth/verify";
export const GetVerifyByEmailAPIUrl = api + "/api/auth/verifybyemail";

export const GetQuickPayAPIUrl = api + "/api/QuickPay";
export const GetQuickPaySSNAPIUrl = api + "/api/QuickPaySSN";
export const QuickPayPersonal = api + "/api/QuickPayPersonal";
export const QuickPayBusiness = api + "/api/QuickPayBusiness";

export const GetWebOffersAPIUrl = api + "/Api/WebOffers/SetWebOffer";
export const GetWebOffersCardAPIUrl =
  api + "/Api/WebOfferUseExistingCard/SetWebOffer";
export const GetReturnAPIUrl = api + "/api/WebOffers/ReturnWebOffer";
export const GetTransactionsAPIURL = api + "/Api/accounts/GetTransactions";
export const GetCardPaymentAPIURL = api + "/Api/WebOffers/CardPayment";

export const GetRelexPostBackAPIURL = api + "/api/HppResponse/RealexResult";
export const GetRealexPayURLAPI = "https://pay.sandbox.realexpayments.com/pay";

export const GetWelcomeDetails = api + "/Api/WelcomeDetail/GetWelcomeDetails";
export const GetReset = api + "/api/auth/SendResetCredentialCode";
export const GetBugetColumns = api + "/Api/Budgets/getBudgetColumns";
export const CreateBudget = api + "/Api/Budgets/CreateBudget";
export const CreateBudgetAndAttachDocuments =
  api + "/Api/Budgets/CreateBudgetAndAttachDocuments";

export const OnePaymentURLSettlementOfferNoCard =
  api + "/Api/WebOffers/SettlementOffer";
export const OnePaymentPayNowURLWebOfferUseExistingCard =
  api + "/Api/WebOfferUseExistingCard/SettlementOffer";
export const OnePaymentWebOfferResponseNoCard =
  api + "/Api/HppCreateCard/RealexSettlementResult";

export const PayNowURLNoCard = api + "/Api/WebOffers/CardPayment";
export const PayNowURLCard = api + "/Api/CardPayments/UseExistingCard";
export const PayNowResponseNoCard =
  api + "/Api/HppCreateCardPayNow/RealexResult";
export const WebOfferResponseNoCard = api + "/Api/HppCreateCard/RealexResult";

export const SaveCallBack = api + "/Api/Callback/CreateCallBackLoggedOn";

export const changePassword = api + "/api/auth/ChangePassword";
export const ChangeBusinessPassword = api + "/api/auth/ChangeBusinessPassword";
export const ChangePersonalPassword = api + "/api/auth/ChangePersonalPassword";

export const changePin = api + "/api/auth/ChangePIN";
export const ChangePersonalPIN = api + "/api/auth/ChangePersonalPIN";
export const ChangeBusinessPIN = api + "/api/auth/ChangeBusinessPIN";

export const sendCode = api + "/api/auth/sendcode";

export const ErrorRedirectURL = api + "/Api/HppCreateCard/RealexResult";

export const GetWelcomeNavigation =
  api + "/Api/WelcomeDetail/getWelcomeNavigation";

export const GetUserMessages = api + "/Api/Messages/GetUserMessages";
export const GetUserMessageDetails =
  api + "/Api/Messages/GetUserMessageDetails?";
//TabaPay
export const QuickPayResultTabaPay = api + "/Api/SSOResponse/QuickPayResult";

export const CreateCardRequestTabaPay =
  api + "/Api/SSORequest/CreateCardRequest";
export const PayNowAndSaveCardResultTabaPay =
  api + "/Api/SSOResponse/PayNowAndSaveCardResult";
export const UseExistingCardTabaPay =
  api + "/Api/SSOCardPayments/UseExistingCard";

export const CreateOfferRequestTabaPay =
  api + "/Api/SSORequest/CreateOfferRequest";
export const SaveOfferResultTabaPay = api + "/Api/SSOResponse/SaveOfferResult";
export const SetWebOfferTabaPay = api + "/Api/SSOCardPayments/SetWebOffer";

export const CreateSettlementOfferRequestTabaPay =
  api + "/Api/SSORequest/CreateSettlementOfferRequest";
export const SaveSettlementOfferResultabaPay =
  api + "/Api/SSOResponse/SaveSettlementOfferResult";
export const SetWebSettlementOfferTabaPay =
  api + "/Api/SSOCardPayments/SetWebSettlementOffer";
